import { captureException } from '@sentry/vue'

class CaptureError extends Error {}
CaptureError.prototype.name = 'CaptureError'

class NetworkError extends Error {}
NetworkError.prototype.name = 'NetworkError'

export class ImageError extends Error {}
ImageError.prototype.name = 'ImageError'

const networkErrorList = ['NetworkError', '[Network]', 'Network Error']
function convertErrorException(exception: Error | string, error: Error | null): Error {
  if (typeof exception !== 'string') {
    return exception
  }
  if (
    networkErrorList.some((item) => exception.includes(item)) ||
    (error !== null && networkErrorList.some((item) => error.message.includes(item)))
  ) {
    return new NetworkError(exception)
  }
  return new CaptureError(exception)
}

function convertToSentryFormat(obj: Record<string, unknown>): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (value === null) {
        return [key, 'null']
      } else if (value === undefined) {
        return [key, 'undefined']
      } else if (typeof value === 'object') {
        if (value instanceof Map) {
          return [key, JSON.stringify(Object.fromEntries(value.entries()), null, 2)]
        } else if (value instanceof Set) {
          return [key, JSON.stringify([...value], null, 2)]
        }
        return [key, JSON.stringify(value, null, 2)]
      }
      return [key, value]
    }),
  )
}

// TODO(Benson): 最好改名字，但目前不知道要改什麼名字
export const sentry = {
  error: (exception: Error | string, errorMessages: Record<string, unknown> = {}): void => {
    // 這邊有點微 hard code，因為都會直接把 error 打進來，希望透過 error 元素過濾一次錯誤類型是什麼
    const error =
      typeof exception === 'string' &&
      'error' in errorMessages &&
      errorMessages.error instanceof Error
        ? errorMessages.error
        : null
    captureException(convertErrorException(exception, error), {
      contexts: {
        'Error Messages': convertToSentryFormat(errorMessages),
      },
    })

    console.error(exception)
  },
  warn: (message: string): void => {
    // warn 訊息我先不上傳 sentry，太雜了，但還是覺得抽出來以後比較好取代

    console.warn(message)
  },
}
